@import url(https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* POPPINS */
h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 40pt;
  margin: 0;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 34pt;
  margin: 0;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 14pt;
  margin: 0;
}

h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14pt;
  margin: 0;
}

h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12pt;
  margin: 0;
}

[body-1] {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 12pt;
  margin: 0;
}

[body-2] {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12pt;
  margin: 0;
}

caption {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 10pt;
  margin: 0;
}

[button-1] {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12pt;
  margin: 0;
}

[button-2] {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 10pt;
  margin: 0;
}

[overline] {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14pt;
  margin: 0;
}

/* Covered by your grace */

h1[covered='true'] {
  font-family: 'Covered By Your Grace', cursive;
  font-weight: bold;
  font-size: 40pt;
}

h2[covered='true'] {
  font-family: 'Covered By Your Grace', cursive;
  font-weight: bold;
  font-size: 35pt;
}

h3[covered='true'] {
  font-family: 'Covered By Your Grace', cursive;
  font-weight: bold;
  font-size: 30pt;
}

/** Tablet */
@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  h1[covered='true'] {
    font-size: 50pt;
  }

  h2[covered='true'] {
    font-size: 40pt;
  }

  h3[covered='true'] {
    font-size: 35pt;
  }
}

/** Mobile */
@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) {
  h1[covered='true'] {
    font-size: 50pt;
  }
  h2[covered='true'] {
    font-size: 40pt;
  }
  h3[covered='true'] {
    font-size: 35pt;
  }
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v125/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v100/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2)
    format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v99/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmPq_HTTw.woff2)
    format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v100/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvRImcycg.woff2)
    format('woff2');
}
/* fallback */
@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconstwotone/v103/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0NjbrHg.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-sharp {
  font-family: 'Material Icons Sharp';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

body {
  margin: auto;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

/** Font Styles */

[regular] {
  font-weight: normal;
}
[medium] {
  font-weight: 500;
}
[bold] {
  font-weight: bold;
}

/** Import material design icons */

